/**
 * Created by amotis on 29/05/2020.
 */
angular
    .module('annexaApp')
    .component('annexaCronGen', {
        templateUrl: './components/querys/annexa-cron-gen/annexa-cron-gen.html',
        controller: ['$scope', function () {}],
        bindings: {
        	cronExpression: '=',
        	cronOptions: '=',
        	isCronDisabled: '='
        }
    })