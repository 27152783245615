angular
    .module('annexaApp')
    .component('tableDataQuery', {
        templateUrl: './components/querys/table-data-query/table-data-query.html',
        controller: ['DTOptionsBuilder', 'DTColumnBuilder', 'RestService', 'Language', '$compile', '$filter', '$rootScope', '$compile', '$scope', 'QueryFactory', 'DialogsFactory',
            function(DTOptionsBuilder, DTColumnBuilder, RestService, Language, $compile, $filter, $rootScope, $compile, $scope, QueryFactory, DialogsFactory) {
            var vm = this;
            vm.additionalFilter = {};

            var getFilterCall = function(){
                return {};
            }
            var getFilterCallAux = function(){
                return vm.additionalFilter;
            }

            var renderColumn = function (data, type, full, meta){
                    var content = '';
                    if(meta && meta.col > -1 && full && full.length > meta.col && full[meta.col].valueString){
                        content += full[meta.col].valueString;
                    }
                    return content;
                }
            this.$onInit = function () {
                vm.tableDefinition = {
                    id: 'tableExecuteQuery',
                    sort: [[0, 'asc']],
                    filterCall: getFilterCall(),
                    filterCallAux: getFilterCallAux(),
                    filterCallFunc: getFilterCall,
                    filterCallAuxFunc: getFilterCallAux,
                    columns: [],
                    containerScope: $scope,
                    objectsPaginated: function(pag, size){
                        return QueryFactory.executeQuery(vm.query.id, getFilterCallAux(), pag ,size);
                    }
                }
                $scope.$on('executeQueryResultErrorComponent',function(event,args){
                    $rootScope.$broadcast('executeQueryResultError',{queryId:vm.query.id});
                });

                $scope.$on('executeQueryResult', function (event, args) {
                    if (args && args.queryId && args.additionalFilter && vm.query && args.queryId == vm.query.id) {
                        if(vm.tableDefinition && vm.tableDefinition.columns && vm.tableDefinition.columns.length > 0) {
                            vm.tableDefinition.reloadInternalData(true, true);
                        }else{
                            QueryFactory.executeQuery(args.queryId, args.additionalFilter, 0, 1, true).then(function (data) {
                                vm.additionalFilter = args.additionalFilter;
                                if (data && data.content && data.content.length > 0) {
                                    if (data.content[0].length > 0) {
                                        data.content = $linq(data.content).select(function (x) {
                                            var object = {};
                                            _.forEach(x, function (column) {
                                                if (column.alias) {
                                                    object[column.alias] = {
                                                        value: column.value,
                                                        valueString: column.valueString
                                                    };
                                                }
                                            });
                                            return object;
                                        }).toArray();
                                        var keys = Object.keys(data.content[0]);
                                        vm.tableDefinition.columns.length = 0;
                                        _.forEach(keys, function (column) {
                                            vm.tableDefinition.columns.push({
                                                id: column + '.valueString',
                                                title: column,
                                                sortable: false,
                                                render: renderColumn
                                            });
                                        });
                                    }
                                    if (vm.tableDefinition.columns && vm.tableDefinition.columns.length != 0) {
                                        vm.tableDefinition.filterCall = getFilterCall();
                                        vm.tableDefinition.filterCallAux = getFilterCallAux();
                                        if(args.modal){
                                            args.modal.additionalButton = {
                                                label: 'global.querys.execute.export',
                                                click: function(modal){
                                                    QueryFactory.exportQuery(vm.query.id, vm.additionalFilter, modal);
                                                }
                                            };
                                        }
                                    }
                                }else{
                                	if(args.modal){
                                        if(args.modal.alerts){
                                            args.modal.alerts.length = 0;
                                        }else{
                                            args.modal.alerts = [];
                                        }
                                        args.modal.alerts.push({msg: $filter('translate')('global.querys.sEmptyTable')});
                                    }else {
                                        DialogsFactory.error('global.querys.sEmptyTable', $filter('translate')('DIALOGS_ERROR_MSG'));
                                    }
                                }
                            }).catch(function (error) {
                                $rootScope.$broadcast('executeQueryResultError', {queryId:args.queryId});
                            });
                        }
                    }
                });
            };
        }],
        bindings: {
            query: '=?'
        }
    })